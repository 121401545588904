import React, { useEffect, useState } from 'react'
import dana from '../../../img/dana.png'
import branda from '../../../img/Brandaush.png'
import clients from '../../../img/clients.svg'
import plan from '../../../img/plan.svg'
import quote from '../../../img/quote.svg'
import founderTitle from '../../../img/the_founder.svg'
import brandaImage from '../../../img/mobile-premium-image-cropped.png'
import HomeVideo from '../../templates/Home/HomeVideo'
import { useAppSelector } from '../../../app/hooks'
import { preSignUpUser, users } from '../../../app/services/Api'
import {
  setCompanyName,
  setCountry,
  setEmail,
  setFirstName, setGender,
  setLastName,
  setProfession
} from '../../../app/quiz/quizSlice'
import { useDispatch } from 'react-redux'

interface Props {
  className?: string
}

const AboutUsPageInfo: React.FC<Props> = ({ className }): JSX.Element => {
  const isMobile = window.innerWidth <= 768
  const [quizLink, setQuizLink] = useState<string>('/quiz')
  const email = useAppSelector(state => state.quizReducer.email)
  const firstName = useAppSelector(state => state.quizReducer.first_name)
  const lastName = useAppSelector(state => state.quizReducer.last_name)
  const country = useAppSelector(state => state.quizReducer.country)
  const gender = useAppSelector(state => state.quizReducer.Gender)
  const [target, setTarget] = useState<string>('_self')

  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const reportToken = queryParams.get('from-report')

  useEffect(() => {
    if (firstName !== '') {
      setQuizLink(`/quiz?first_name=${firstName}&last_name=${lastName}&email=${email}&country=${country}&gender=${gender}`)
      setTarget('_blank')
    } else if (email !== '') {
      setQuizLink(`/sign-in?email=${email.replaceAll(' ', '+')}`)
      setTarget('_blank')
    } else {
      setQuizLink('/quiz')
      setTarget('_self')
    }
  }, [email])

  useEffect(() => {
    if (reportToken !== null) {
      void users.getReport(reportToken).then((response) => {
        localStorage.removeItem('pre_sign_up_user')
        void preSignUpUser.sendEmailVerification(
          response.email,
          response.first_name,
          response.last_name,
          response.country,
          true
        ).then(() => {
          dispatch(setLastName(response.last_name))
          dispatch(setFirstName(response.first_name))
          dispatch(setEmail(response.email))
          dispatch(setCountry(response.country))
          dispatch(setCompanyName(response.workplace))
          dispatch(setProfession(response.occupation))
          dispatch(setGender(response.gender))
        }).catch(() => {
          dispatch(setEmail(response.email))
        })
      })
    }
  }, [])

  return (
        <div className={`about-us-container ${className}`}>
            <section
                className={`bg-gradient-to-b from-[#eae1b0] to-[#e7c98e] md:p-5 sm:p-10 md:px-20 md:pt-0 xl:pb-60 2xl:pb-20 flex ${isMobile ? 'flex-col items-center' : 'flex-row items-start'} relative`}>
                <div className="w-full h-full md:w-1/2 p-4 md:pt-40">
                    <div
                        className='main_header_home font-bold text-4xl md:text-7xl text-center md:text-left md:mb-2 mt-16 md:mt-0'>
                        We&apos;re myBranda
                    </div>
                    <div
                        className='text-orange-500 font-bold text-2xl md:text-3xl text-center md:text-left mb-8 md:mt-0'>
                        And We Build Human Brands
                    </div>
                    <p className="my-2 text-xl text-center md:text-left">Meet Branda, your Human Branding AI agent.
                        Designed especially to help you build your authentic brand and cut 80% off your self-branding
                        hassle! myBranda® platform will craft your personal Brand Identity, inspire you with countless
                        Brand Content ideas, and write original content that sounds like YOU! </p>

                  <div className='md:hidden py-2'>
                    <img src={brandaImage} className='w-full rounded-xl' alt='branda' />
                  </div>

                    <div className='flex flex-col md:flex-row my-8'>
                        <div className='flex flex-col md:flex-row items-center'>
                            <img src={clients} alt="clients"
                                 className="fill-black w-1/3 md:w-1/4 xl:w-1/5 h-auto mr-6"/>
                            <div className='flex flex-col md:items-start items-center my-6 md:my-0'>
                                <p className="main_header_home text-2xl mb-2 font-bold">Get Noticed</p>
                                <p className='mb-2 text-center md:text-left'>Become more visible and attract your dream customers and employers!</p>
                                <a href={quizLink}
                                   target={target}
                                   className='border w-44 mt-3 brand_me_up_button text-center rounded-lg px-4 py-3 text-white font-bold'>
                                    Start For Free
                                </a>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row items-center'>
                            <img src={plan} alt="plan" className="w-1/3 md:w-1/4 xl:w-1/5 h-auto mr-6"/>
                            <div className='flex flex-col md:items-start items-center my-6 md:my-0'>
                                <p className="main_header_home text-2xl mb-2 font-bold">Be Authentic</p>
                                <p className='mb-2 text-center md:text-left'>Create content that resonates with the real YOU.</p>
                                <a href={quizLink}
                                   target={target}
                                   className='border w-44 mt-3 brand_me_up_button text-center rounded-lg px-4 py-3 text-white font-bold'>
                                    Start For Free
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className='md:mt-28'>
                        <p className='main_header_home text-black text-5xl md:text-6xl text-center md:text-left italic'>
                            <img src={quote} alt="plan" className="w-40 -mb-36 -ml-10 opacity-10"/>
                            If You Don’t Tell Your Story, Somebody Else Will
                        </p>
                        <b className='opacity-50'><sub>&mdash; Frank X. Walker</sub></b>
                    </div>
                </div>
                <div className="hidden md:block w-full h-full md:w-1/2 p-4 md:px-4 md:py-0">
                    <img src={branda} alt="branda" className="w-full full"/>
                </div>

                <section
                    className='p-3 py-10 flex flex-col items-center justify-center about-us-center-div text-center md:absolute md:h-[500px] md:bottom-[-250px] md:left-[50%] md:-translate-x-1/2 bg-white md:w-3/4 bg-cover bg-center'>
                    <div className='text-black md:w-1/2'>
                        <h1 className='text-4xl md:text-6xl mb-10 md:mb-16'>Why Us?</h1>
                        <p className='mb-10 md:mb-16'>
                            We bring over 16 years of experience in Branding, Digital Strategy, Business, Data &
                            Algorithms, and AI models development. Whether you&apos;re an entrepreneur, freelancer,
                            or simply
                            someone looking to make a mark in your industry, we&apos;re here to help you unleash your full
                            Brand potential. Join us on this exciting journey of human brand empowerment!
                        </p>
                        <a href={quizLink} target={target} className='brand_me_up_button rounded-lg px-6 py-3 text-white'>Sign me up!</a>
                    </div>
                </section>
            </section>
            <div className='p-5 pb-16 bg-center bg-cover about-us-bottom-div from-[#eae1b0] to-[#6A34DD]'>
                <section className={`sm:p-10 md:p-20 flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
                    <div className="w-full md:w-1/2 p-4 md:mt-48">
                        <img src={dana} alt="dana" className="w-full h-auto mx-auto"/>
                    </div>
                    <div className="w-full md:w-1/2 px-0 py-4 md:p-4 md:pt-40 text-center md:text-left md:mt-48">
                        <h2 className="main_header_home font-bold text-7xl">
                            <img src={founderTitle} alt=""/>
                        </h2>
                        <p className="mt-5 mb-16 text-lg font-light">
                            I’m Dana, myBranda’s founder and CEO.
                            With over 16 years of marketing experience at global tech companies and branding agencies,
                            I’ve had the privilege of helping hundreds of businesses build impactful brands. But Branding isn’t just for products or companies—each of us is a brand with a unique story, values, and identity to share with the world. That’s exactly why myBranda was born—to empower individuals to own their narrative and shine. <br/>
                            Our mission is to empower Human Brands. We’re here to make your life easier by automatically creating valuable content that allows you to build your perfect brand and attract more opportunities!

                        </p>
                        <a href='/contact-us' className='bg-[#EE9B39] rounded-lg px-6 py-4 text-white shadow'>Contact us!</a>
                    </div>
                </section>
            </div>

            <div
              className='md:hidden main_header_home font-bold text-4xl md:text-7xl text-center md:text-left md:mb-2 mt-16 md:mt-0'>
                Meet Branda
            </div>
            <div className="mt-10 mb-6 md:hidden w-full px-4">
                <HomeVideo/>
            </div>

            <div className='flex justify-center md:hidden'>
                <a href={quizLink}
                   target={target}
                   className='mb-10 mx-auto border w-44 mt-0 brand_me_up_button text-center rounded-lg px-4 py-3 text-white font-bold'>
                  Build My Brand!
                </a>
            </div>

            <div className='hidden md:block mt-[-10px] md:mt-[-25px]'>
                <HomeVideo />
            </div>

        </div>
  )
}

export default AboutUsPageInfo
