import Steps from '../../../molecules/Steps/Steps'
import { ProgressBar } from '../../../atoms/ProgressBar/ProgressBar'
import React, { useEffect, useState } from 'react'
import Header from '../../../atoms/Heading/Heading'
import TextField from '@mui/material/TextField'
import { users } from '../../../../app/services/Api'
import { PAGE, setCurrentUser } from '../../../../app/quiz/quizSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import LoadingButton from '@mui/lab/LoadingButton'
import { PopoverTooltip } from '../../../molecules/PopOver/PopoverTooltip'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export function EmployerPage ({ className, currentPage, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.quizReducer.currentUser)
  const reportCompanyName = useAppSelector(state => state.quizReducer.companyName)
  const [currentCompanyName, setCurrentCompanyName] = useState<string>('')
  const [offering, setOffering] = useState<string>('')
  const [isSectionVisible, setIsSectionVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const updateUser = async (data: object) => {
    if (user === null) {
      return
    }

    await users.updateRegisteredUser(user.id, data).then(() => {
      dispatch(setCurrentUser({
        ...user,
        ...data
      }))
    })
  }

  const handleNextPage = () => {
    if (user === null) {
      return
    }

    setIsLoading(true)
    if (!isSectionVisible) {
      void updateUser({ employee_employer_name: currentCompanyName }).then(() => {
        void users.getEmployerOfferings(true).then((response) => {
          setOffering(response.prediction.replaceAll(/\[[0-9]+]/g, ''))
          setIsSectionVisible(true)
        }).finally(() => {
          setIsLoading(false)
        })
      })

      return
    }

    void users.updateRegisteredUser(user.id, {
      employee_employer_name: currentCompanyName,
      employee_employer_offering: offering,
      step_name: 'Employee Keywords'
    }).then(() => {
      dispatch(setCurrentUser({
        ...user,
        employee_employer_name: currentCompanyName,
        employee_employer_offering: offering
      }))

      handleShowPage(PAGE.EMPLOYEE_KEYWORDS)
    })
  }

  useEffect(() => {
    if (currentCompanyName === '') {
      setIsSectionVisible(false)
    }
  }, [currentCompanyName])

  useEffect(() => {
    if (user === null) {
      return
    }

    if (user.employee_employer_name !== null) {
      setCurrentCompanyName(user.employee_employer_name)
      setIsSectionVisible(true)
    } else if (reportCompanyName !== '') {
      setCurrentCompanyName(reportCompanyName)
    }

    if (user.employee_employer_offering !== null) {
      setOffering(user.employee_employer_offering)
    }
  }, [])

  return (
      <div className={className}>
          <Steps currentPage={currentPage}/>
          <ProgressBar value={5} isRegistration={true} className='w-full md:w-1/2 mx-auto mt-0'/>

          <Header className='text-lg md:text-2xl font-medium mt-2 md:mt-4 mb-0 md:mb-2 text-center'
                  text="Where Do You Work?"/>

          <p className='text-sm md:text-base text-center mb-4'>
              Write the name of the company you&apos;re currently employed at. <br/>
              <PopoverTooltip
                  textClassName='underline decoration-dotted cursor-help pt-2 md:pt-0 text-sm'
                  text={'Why are we asking this?'}
                  tooltip="We want to tailor some of your posts to your current occupation effectively.<br/>This can help you further enhance your professional authority!" />
          </p>

          <div className='w-[90%] md:w-[50%] visible_1'>
              <TextField
                  onChange={(e) => {
                    setCurrentCompanyName(e.target.value)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      handleNextPage()
                    }
                  }}
                  value={currentCompanyName}
                  id="outlined-basic"
                  variant="outlined"
                  className={'w-full'}
              />
          </div>

          <div className={`w-[90%] md:w-[50%] mt-2 md:mt-6 ${isSectionVisible ? 'visible_1' : 'hidden'}`}>
              <Header className='text-lg md:text-2xl font-medium mt-4 mb-2 text-center'
                      text={`What does ${currentCompanyName} offer?`}/>

              <TextareaAutosize
                    minRows={4}
                  id="company-brand-offering"
                  className='w-full bg-white border border-gray-300 rounded p-2'
                  value={offering}
                  onChange={(e) => {
                    setOffering(e.target.value)
                  }}
              />
              <div className='text-center'>
                <span className='text-center text-xs mt-2'>Edit the description if not accurate.</span>
              </div>
          </div>

          <LoadingButton loading={isLoading} onClick={handleNextPage}
                  className=' quiz_form_button !text-white !capitalize !rounded-lg !px-9 !py-2.5 text-center relative md:!h-14 !mt-4'>
                  Next
              </LoadingButton>
          </div>
  )
}
