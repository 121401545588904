import React, { useEffect, useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import { useAppSelector } from '../../../app/hooks'
import { setBusinessTypeValue, setCurrentUser, UserType } from '../../../app/quiz/quizSlice'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import Button from '@mui/material/Button'
import { professions, users } from '../../../app/services/Api'
import { toast } from 'react-toastify'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { Autocomplete, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'

interface Profession {
  id: number
  name: string
}

interface Props {
  handleBackPage: (page: string) => void
}

export default function BasicInfo ({ handleBackPage }: Props) {
  const dispatch = useDispatch()
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)
  const [profession, setProfession] = useState<Profession | null>(null)
  const [professionValue, setProfessionValue] = useState<string>('')
  const [availableProfessions, setAvailableProfessions] = useState<Profession[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  const [businessOffer, setBusinessOffer] = useState<string>('')
  const [businessKeywords, setBusinessKeywords] = useState<string>('')

  const [professionalExpertise, setProfessionalExpertise] = useState<string>('')
  const [soloKeywords, setSoloKeywords] = useState<string>('')

  useEffect(() => {
    if (currentUser !== null) {
      if (currentUser?.user_type === UserType.EMPLOYEE) {
        // TODO: Implement
      } else if (currentUser.is_business) {
        void professions.getBusinessTypes(currentUser.id).then((response) => {
          setAvailableProfessions(response)
          handleUpdateFields(currentUser.business_type_name, response)
        })

        setBusinessOffer(currentUser?.business_offer ?? '')
        setBusinessKeywords(currentUser?.business_keywords ?? '')
      } else {
        void professions.getAllProfessions(currentUser.id).then((response) => {
          setAvailableProfessions(response)
          handleUpdateFields(currentUser.profession, response)
        })

        setProfessionalExpertise(currentUser.professional_expertise ?? '')
        setSoloKeywords(currentUser.expertise_keywords ?? '')
      }
    }
  }, [currentUser])

  const handleUpdateFields = (userProfession: string | null, professions: Profession[]) => {
    const option = userProfession !== null
      ? professions.find((item: Profession) => {
        return item.name === userProfession
      })
      : null

    setProfession(option ?? null)
    setProfessionValue(option?.name ?? '')
    setInputValue(option?.name ?? '')
  }

  const handleProfileUpdate = () => {
    if (currentUser === null) {
      return
    }

    if (currentUser.is_business) {
      void users.updateRegisteredUser(currentUser.id, {
        business_type: profession?.id,
        business_offer: businessOffer,
        business_keywords: businessKeywords
      }).then(() => {
        toast.success('Profile updated successfully!')

        dispatch(setCurrentUser({
          ...currentUser,
          business_offer: businessOffer,
          business_keywords: businessKeywords,
          business_type_name: profession?.name ?? '',
          business_type: profession?.id ?? 0
        }))

        handleBackPage('init-profile')
      })
    } else if (currentUser.user_type === UserType.ENTREPRENEUR) {
      void users.updateRegisteredUser(currentUser.id, {
        profession: profession?.name,
        professional_expertise: professionalExpertise,
        expertise_keywords: soloKeywords
      }).then(() => {
        toast.success('Profile updated successfully!')

        dispatch(setCurrentUser({
          ...currentUser,
          profession: profession?.name ?? '',
          professional_expertise: professionalExpertise,
          expertise_keywords: soloKeywords
        }))

        handleBackPage('init-profile')
      })
    }
  }

  const handleChange = (event: React.SyntheticEvent, value: string | null) => {
    if (currentUser === null) {
      return
    }

    dispatch(setBusinessTypeValue(value ?? null))
    setProfessionValue(value ?? '')
    setInputValue(value ?? '')

    const option = availableProfessions.find((item) => {
      return item.name === value
    })

    setProfession(option ?? null)
  }

  const ProfessionDropdown = (): JSX.Element => {
    return (
      <Autocomplete
        freeSolo={true}
        disablePortal
        forcePopupIcon={true}
        className={'w-44 md:w-72'}
        id="combo-box-business-types"
        options={availableProfessions.map((profession) => profession.name)}
        value={professionValue}
        inputValue={inputValue}
        onChange={handleChange}
        popupIcon={<ArrowDropDownIcon />}
        renderInput={(params) =>
          <TextField
            className='text-xs'
            {...params} />}
        sx={{
          fontSize: '10px',
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2'
          }
        }}
      />
    )
  }

  return (
    <div className='flex flex-col justify-center items-start md:justify-start md:items-start w-full gap-3'>
      <Header className='font-medium  text-xl md:text-3xl leading-7 text-black block mb-6 mt-7 md:4' text='Your Details' />
      <div className='flex flex-row items-center gap-3 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-24'>
          Full Name
        </label>
        <input placeholder="Your Name" type='text' className='text-xs border border-transparent p-2 w-44 h-7' value={`${currentUser?.first_name} ${currentUser?.last_name}`} disabled />
      </div>
      <div className='flex flex-row items-center gap-3 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-24'>
        Gender
        </label>
        <input placeholder="Your Gender" type='text' className='text-xs border border-transparent p-2 w-44 h-7' value={currentUser?.gender} disabled />
      </div>
      <div className='flex flex-row items-center gap-3 sm:gap-14 mb-2 flex-shrink-0'>
        <label className='font-thin text-slate-400 text-sm w-24'>
          Email address
        </label>
        <input id='email' type='text' className='text-xs md:text-sm bg-slate-50 p-2 w-44 md:w-72 h-7' value={currentUser?.email} disabled />
      </div>

      {currentUser?.user_type === UserType.ENTREPRENEUR && (
        <>
        <div className='flex flex-row items-center gap-3 sm:gap-14 mb-2'>
          <label className='font-thin text-slate-400 text-sm w-24'>
            Profession
          </label>
          <ProfessionDropdown />
        </div>

          <div className='flex flex-row items-start gap-3 sm:gap-14 mb-2'>
            <label className='font-thin text-slate-400 text-sm w-24'>
              Professional Expertise
            </label>
            <TextareaAutosize
              className='p-2 w-44 md:w-72 text-xs border'
              minRows={3}
              onChange={(e) => {
                setProfessionalExpertise(e.target.value)
              }}
              value={professionalExpertise}
            />
          </div>
          <div className='flex flex-row items-start gap-3 sm:gap-14 mb-2'>
            <label className='font-thin text-slate-400 text-sm w-24'>
              Discussion Topics
            </label>
            <TextareaAutosize
              className='p-2 w-44 md:w-72 text-xs border'
              minRows={3}
              onChange={(e) => {
                setSoloKeywords(e.target.value)
              }}
              value={soloKeywords}
            />
          </div>
      </>
      )}

      {currentUser?.user_type === UserType.BUSINESS && (
        <>
        <div className='flex flex-row items-center gap-3 sm:gap-14 mb-2'>
          <label className='font-thin text-slate-400 text-sm w-24'>
            Business Type
          </label>
          <ProfessionDropdown />
        </div>
        <div className='flex flex-row items-start gap-3 sm:gap-14 mb-2'>
          <label className='font-thin text-slate-400 text-sm w-24'>
            Business Offer
          </label>
          <TextareaAutosize
            className='p-2 w-44 md:w-72 text-xs border'
            minRows={3}
            onChange={(e) => {
              setBusinessOffer(e.target.value)
            }}
            value={businessOffer}
            />
        </div>
        <div className='flex flex-row items-start gap-3 sm:gap-14 mb-2'>
          <label className='font-thin text-slate-400 text-sm w-24'>
            Business discussion topics
          </label>
          <TextareaAutosize
            className='p-2 w-44 md:w-72 text-xs border'
            minRows={3}
            onChange={(e) => {
              setBusinessKeywords(e.target.value)
            }}
            value={businessKeywords}
            />
        </div>
        </>
      )}

      <div className='flex flex-row items-start gap-3 sm:gap-14 mb-2'>
        <label className='font-thin text-slate-400 text-sm w-24'>

        </label>
        <div className='w-44 md:w-72 md:text-right '>
          <Button
            onClick={handleProfileUpdate}
            color='inherit'
            className='quiz_form_button !mt-4 !capitalize !text-white !font-medium !rounded-lg !text-sm px-4 !py-2 text-center mr-2 !h-9 !w-24'
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
