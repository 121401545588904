/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Autocomplete, Chip,
  TextField
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { professions, users } from '../../../app/services/Api'
import { PAGE, setCurrentUser } from '../../../app/quiz/quizSlice'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

interface Profession {
  id: number
  name: string
}

export default function QuizProfessionPageForm ({ className, handleShowPage }: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isDisabled, setIsDisabled] = useState(true)
  const [availableProfessions, setAvailableProfessions] = useState<Profession[]>([])
  const [isInputEmpty, setIsInputEmpty] = useState(true)
  const [isExpertiseSectionVisible, setIsExpertiseSectionVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(() => {
    const storedProfession = localStorage.getItem('profession')
    return storedProfession ?? null
  })
  const [keywords, setKeywords] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [keywordInputValue, setKeywordInputValue] = useState<string>('')
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const otherOption: Profession = {
    id: 9999,
    name: 'Other'
  }

  const handleCustomValue = () => {
    setInputValue(inputValue.trim())

    if ((inputValue !== '') && !availableProfessions.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      setAvailableProfessions((items) => [...items, { id: -1, name: inputValue }])
    }

    const option = availableProfessions.find((item) => {
      return item.name.toLowerCase() === inputValue.toLowerCase()
    })

    setSelectedOption(option?.name ?? '')
  }

  const handleInputChange = (event: any, value: any) => {
    setSelectedOption(null)
    setInputValue(value)

    if (value !== '') {
      setIsInputFocused(false)
    }
  }

  useEffect(() => {
    if (currentUser === null) {
      return
    }

    void professions.getAllEmployeeProfessions(currentUser.id)
      .then((response) => {
        setAvailableProfessions([...response, otherOption])

        if (currentUser.employee_professional_title) {
          const option = response.find((item: Profession) => {
            return item.id === currentUser.employee_professional_title
          })

          setSelectedOption(option?.name ?? '')
          setInputValue(option?.name ?? '')
        }

        if (currentUser.employee_expertise) {
          setKeywords(currentUser.employee_expertise.split(', ') ?? [])
        }

        setIsExpertiseSectionVisible(currentUser.employee_expertise !== null)
      })
  }, [])

  useEffect(() => {
    setIsInputEmpty(keywordInputValue.trim() === '')
  }, [keywordInputValue])

  const addKeyword = (): void => {
    const trimmedValue = keywordInputValue.trim()
    if (trimmedValue.length > 0) {
      const words = trimmedValue.split(',')
      const newKeywords = words.map(word => word.trim())

      setKeywords([...keywords, ...newKeywords])
      setKeywordInputValue('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      addKeyword()
    }
  }

  const handleChange = async (event: any, value: any) => {
    setSelectedOption(value || '')
    setInputValue(value || '')

    const option = availableProfessions.find((item) => {
      return item.name === value
    })

    setIsInputFocused(option !== null && option !== undefined && option.id === otherOption.id && value !== '')

    if (option) {
      if (option.id === otherOption.id) {
        setSelectedOption('')
        setInputValue('')
      }
    }
  }

  useEffect(() => {
    setIsDisabled((!selectedOption && !inputValue || (keywords.length === 0 && isExpertiseSectionVisible)))
  }, [selectedOption, inputValue, keywords, isExpertiseSectionVisible])

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isDisabled) {
      return
    }

    if (!isExpertiseSectionVisible) {
      setIsExpertiseSectionVisible(true)
      setIsDisabled(true)
      return
    }

    const option = availableProfessions.find((item) => {
      return item.name === selectedOption
    })

    if (selectedOption === '' || option?.id === -1) {
      void professions.createEmployeeProfession({
        name: inputValue,
        email: currentUser?.email
      }).then((response) => {
        saveAndGoToNextStep(response.id)
      })
    } else {
      saveAndGoToNextStep(option?.id ?? null)
    }
  }

  const saveAndGoToNextStep = (id: number | null) => {
    if (currentUser === null) {
      return
    }

    void users.updateRegisteredUser(currentUser?.id ?? 0, {
      employee_professional_title: id,
      employee_expertise: keywords.join(', '),
      step_name: 'Employee Status'
    }, true).then(() => {
      dispatch(setCurrentUser({
        ...currentUser,
        employee_professional_title: id,
        employee_expertise: keywords.join(', ')
      }))
    }).finally(() => {
      handleShowPage(PAGE.EMPLOYEE_STATUS)
    })
  }

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  useEffect(() => {
    updateUser()
  }, [keywords, selectedOption])

  const updateUser = () => {
    if (currentUser === null) {
      return
    }

    const option = availableProfessions.find((item) => {
      return item.name.toLowerCase() === selectedOption?.toLowerCase()
    })

    if (option === undefined) {
      return
    }

    void users.updateRegisteredUser(currentUser.id ?? 0, {
      employee_professional_title: option.id,
      employee_expertise: keywords.join(', ')
    }, true).finally(() => {
      dispatch(setCurrentUser({
        ...currentUser,
        employee_professional_title: option.id,
        employee_expertise: keywords.join(', ')
      }))
    })
  }

  return (
    <div className={className}>
      <p
        className="inline-block text-lg px-5 pb-2 md:py-2 md:p-0 mt-2 visible_1">
        What&apos;s your professional title?
      </p>

      <Autocomplete
        freeSolo={true}
        disablePortal
        forcePopupIcon={true}
        id="combo-box-profession"
        options={availableProfessions.map((profession) => profession.name)}
        value={selectedOption}
        inputValue={inputValue}
        onChange={handleChange}
        popupIcon={<ArrowDropDownIcon/>}
        renderInput={(params) =>
            <TextField
                focused={isInputFocused}
                {...params}
                label="Type or choose from the list"
                onChange={(e) => {
                  handleInputChange(e, e.target.value)
                }}
                onBlur={handleCustomValue}/>}
        sx={{
          width: isSmallerScreen ? '90%' : '50%',
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: isInputFocused ? 'firebrick' : '#1976d2'
          }
        }}
      />

      <div
          className={`transition-all transform w-[90%] md:w-1/2 ${isExpertiseSectionVisible ? 'h-auto flex flex-col items-center' : 'h-0 invisible'}`}>

        <p
            className="mt-4 md:inline-block text-sm text-center px-5 py-2 md:p-0 md:mt-7 visible_1">
          Write below your professional expertise and specific focus areas.
        </p>

        <div className='flex flex-col mt-2 md:mt-4 md:mb-4 w-full'>
          <TextField
              variant="outlined"
              value={keywordInputValue}
              className='w-full'
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) => {
                if (e.target.value === ',') {
                  return
                }

                setKeywordInputValue(e.target.value)
              }}
              sx={isSmallerScreen
                ? {
                    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                      fontSize: '13px'
                    }
                  }
                : {}}
              InputProps={{
                style: {
                  borderRadius: '8px'
                },
                endAdornment: (
                    <button
                        type="button"
                        className={`rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300 ${isInputEmpty ? '' : 'bg-purple-200'}`}
                        onClick={addKeyword}
                    >
                      Add
                    </button>
                )
              }}
          />
          <span className='text-center text-xs mt-2'>Separate each term with comma or press Add.</span>
        </div>

        <div className="flex flex-wrap justify-center md:justify-start mt-4">
          {keywords.map((keyword, index) => (
              <div key={index} className="m-1">
                <Chip label={keyword} onDelete={handleChipDelete(keyword)}/>
              </div>
          ))}
        </div>
      </div>

      <button
          disabled={isDisabled}
          onClick={handleUpdateUser}
          className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16 !mt-4'
      >
        Next
      </button>
    </div>
  )
}
