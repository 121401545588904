import { COUNTRIES } from '../../atoms/CountryDropdown/countries'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import React, { type SyntheticEvent, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  setCountry, setEmail,
  setFirstName,
  setLastName
} from '../../../app/quiz/quizSlice'
import { ReactComponent as Image } from '../../../img/brand-report-main-img.svg'
import { Search } from '@mui/icons-material'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { toast } from 'react-toastify'
import type dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/he'
import { Autocomplete } from '@mui/material'
import { DateField } from '@mui/x-date-pickers'

interface Report {
  id: string
  first_name: string
  last_name: string
  country: string
  email: string
}

export default function BrandReportForm () {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [isCheckboxVisible, setIsCheckboxVisible] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState(false)
  const name = useAppSelector(state => state.quizReducer.first_name)
  const lastName = useAppSelector(state => state.quizReducer.last_name)
  const email = useAppSelector(state => state.quizReducer.email)
  const country = useAppSelector(state => state.quizReducer.country)
  const company = useAppSelector(state => state.quizReducer.companyName)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [birthDate, setBirthDate] = React.useState<dayjs.Dayjs | null>()

  const [city, setCity] = React.useState<string>('')
  const [birthCountry, setBirthCountry] = React.useState<string | null>(null)
  const [countryValue, setCountryValue] = React.useState<string | null>(null)
  const [profession, setProfession] = React.useState<string>('')
  const [gender, setGender] = React.useState<string>('')
  const genders: string[] = ['Male', 'Female', 'Non-Binary']

  const dispatch = useAppDispatch()
  const inputClassName = ' bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block h-14 p-2.5 dark:dark:focus:ring-blue-500 dark:focus:border-blue-500'
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const handleCountryChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    const countryCode = COUNTRIES.find((option) => option.title === (value ?? '')) ?? null

    setCountryValue(value ?? '')
    dispatch(setCountry(countryCode?.value ?? ''))
  }

  const handleBirthCountryChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    setBirthCountry(value ?? '')
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFirstName(e.target.value))
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLastName(e.target.value))
  }

  const handleDateTimeChange = (dateTime: dayjs.Dayjs | null) => {
    setBirthDate(dateTime)
  }

  const handleGenderChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    setGender(value ?? '')
  }

  const handleProfessionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfession(e.target.value)
  }

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value))
  }

  useEffect(() => {
    setIsDisabled(
      name === '' ||
      lastName === '' ||
      !emailRegex.test(email) ||
      profession === '' ||
      email === '' ||
      birthDate === null ||
      !isChecked ||
      gender === ''
    )
  }, [name, lastName, email, company, isChecked])

  useEffect(() => {
    setIsCheckboxVisible(emailRegex.test(email))
  }, [email])

  useEffect(() => {
    if (!isCheckboxVisible) {
      setIsChecked(false)
    }
  }, [isCheckboxVisible])

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/')
        dispatch(setCountry(response.data.country))
        setCountryValue(response.data.country_name)
        setBirthCountry(null)
      } catch (error) {
        console.log('Error fetching user country:', error)
      }
    }

    void fetchUserCountry()
  }, [dispatch])

  useEffect(() => {
    setIsCheckboxVisible(false)

    if (currentUser === null) {
      return
    }

    void users.getReportByEmail(currentUser.email).then((response) => {
      navigate(`/mybrandid/${response[0].id}`)
    })
  }, [])

  const checkReportStatus = () => {
    const interval = setInterval(() => {
      void users.pingReport(email)
        .then((reports) => {
          if (reports.length > 0) {
            let reportId: string | null = null
            reports.forEach((report: Report) => {
              users.updateReport(report.id, { is_confirmed: true }).catch(() => {})
              reportId = report.id
            })

            clearInterval(interval)
            navigate(`/mybrandid/${reportId}`)
          }
        })
    }, 3000)
  }

  const handleSubmit = () => {
    setLoading(true)

    void users.generateReport({
      email,
      first_name: name,
      last_name: lastName,
      profession,
      birth_city: city,
      birth_country: birthCountry,
      birth_date: birthDate?.format('YYYY-MM-DD'),
      gender,
      country
    }).then(() => {
      checkReportStatus()
    }).catch((error) => {
      toast.error('Something went wrong. Please try again.')
      console.error(error)
    })
  }

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  return (
        <div>
            {loading && <div className={'h-[100vh] w-full fixed flex justify-center items-center left-0 top-0 z-50 bg-[#ffffffbb]'}>
                <span className='inline-flex flex-col justify-center items-center text-lg font-medium mb-16 bg-[#ffffffaa] rounded-xl px-4 py-2'>
                    <Search className='animate-move m-1' fontSize='medium' />
                    <span className='animate-pulse pb-1'>Analyzing your Brand Design</span>
                    <span className='text-sm opacity-75 animate-pulse'>It can take a minute...</span>
                </span>
            </div>}
            <div className='flex flex-col justify-center relative items-center gap-5 visible_1'>
                <Image className='w-36 md:w-52' />
                <div className='flex flex-col justify-center items-center gap-1 mb-2'>
                    <p className='main_header_home text-center text-2xl md:text-3xl font-medium'>Your Human Brand&apos;s Story</p>
                    <p className='text-sm md:text-base font-normal text-center md:text-left break-words w-64 md:w-auto'>
                      Written by AI. Inspired by YOU.
                    </p>
                </div>
            </div>
            <div className='flex pt-2 flex-col justify-center relative items-center visible_1 gap-4 mt-5'>
                <div className='flex w-full md:w-1/2 gap-x-2'>

                    <TextField
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          className: inputClassName
                        }}
                        value={name}
                        className={'w-1/2'}
                        onChange={handleFirstNameChange}
                        label='First Name (English)' />

                    <TextField
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          className: inputClassName
                        }}
                        value={lastName}
                        className={'w-1/2'}
                        onChange={handleLastNameChange}
                        label='Last Name (English)' />
                </div>

                <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      className: inputClassName
                    }}
                    id='profession'
                    value={profession}
                    className={'w-full md:!w-1/2'}
                    onChange={handleProfessionChange}
                    placeholder='Executive Coach, VP Marketing etc.'
                    label="Your Profession (English)" />

              <div className='flex gap-2 w-full md:!w-1/2'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  InputLabelProps={{ shrink: true }}
                  format={'DD/MM/YYYY'}
                  label='Birth date'
                  className='w-1/2'
                  value={birthDate}
                  onChange={handleDateTimeChange} />
              </LocalizationProvider>

              <Autocomplete
                options={genders}
                value={gender}
                onChange={handleGenderChange}
                forcePopupIcon={true}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Gender" />}
                className='w-1/2' />
              </div>

              <Autocomplete
                options={COUNTRIES.map((option) => option.title)}
                forcePopupIcon={true}
                value={birthCountry}
                onChange={handleBirthCountryChange}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Birth country" />}
                className='w-full md:!w-1/2' />

                <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      className: inputClassName
                    }}
                    value={city}
                    className={'w-full md:!w-1/2'}
                    onChange={handleCityChange}
                    label="Birth City" />

                <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      className: inputClassName
                    }}
                    value={email}
                    className={'w-full md:!w-1/2 '}
                    onChange={handleEmailChange}
                    label="Email address" />

                <div className={`${isCheckboxVisible ? 'animate-slideBottom' : 'hidden'} flex md:flex-row items-center justify-center text-xs w-full md:w-1/2 border p-2 rounded`}>
                    <div>
                    <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                        checked={isChecked}
                        onChange={handleChecked}
                    />
                    </div>
                    <div>
                        I consent to receive marketing communications from myBranda LTD via email. I understand I can withdraw my consent at any time by clicking the &apos;unsubscribe&apos; link in any email, or contacting us at this <a
                        className='underline text-blue-800' href="mailto:contact@mybranda.com">email</a>,<br/>For more information on how we use your data, please see our Privacy Policy.
                    </div>
                </div>

              <Autocomplete
                options={COUNTRIES.map((option) => option.title)}
                forcePopupIcon={true}
                value={countryValue}
                clearIcon={false}
                onChange={handleCountryChange}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Where do you currently live" />}
                className='w-full md:!w-1/2' />

                <LoadingButton
                    onClick={handleSubmit}
                    loading={loading}
                    loadingIndicator={<CircularProgress color="inherit" size={28} />}
                    disabled={isDisabled}
                    color='inherit'
                    className='quiz_form_button quiz_form_submition !normal-case !text-white !font-medium !rounded-lg px-5 py-2.5 text-center mr-2 h-14'
                >Tell My Story!</LoadingButton>
            </div>
        </div>
  )
}
