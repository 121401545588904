/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/array-type */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type SocialMediaScore } from './userSocialMedia'

export const PAGE = {
  EMAIL: 'email',
  VERIFICATION_CODE: 'verification_code',
  GOALS: 'goals',
  GENDER: 'gender',
  BUSINESS_TYPE: 'business_type',
  BUSINESS_ABOUT: 'business_about',
  BUSINESS_MORE_INFO: 'business_more_info',
  PROFESSION: 'profession',
  EMPLOYEE_PROFESSION: 'employee_profession',
  EMPLOYEE_STATUS: 'employee_status',
  EMPLOYEE_KEYWORDS: 'employee_keywords',
  EMPLOYEE_EMPLOYER: 'employee_employer',
  SOLO_KEYWORDS: 'solo_keywords',
  BUSINESS_KEYWORDS: 'business_keywords',
  QUIZ_FIRST_STEP: 'quiz_first_step',
  QUIZ_SECOND_STEP: 'quiz_second_step',
  QUIZ_GENIE_STEP: 'quiz_genie_step',
  QUIZ_FINAL_STEP: 'quiz_final_step'
}

export const UserType = {
  ENTREPRENEUR: 'Entrepreneur',
  BUSINESS: 'Business',
  EMPLOYEE: 'Employee'
}

export const EmployeeStatus = {
  EMPLOYED: 'Employed',
  EMPLOYED_LOOKING: 'Employed_Looking',
  UNEMPLOYED: 'Unemployed'
}

export interface Task {
  id: number
  branda: string
  how: string
  when: string
  task_body: string
  task_type: number
  task_title: string
  branda_tip: string | null
  platform: string
  subject_for_ai: boolean
}

export interface Tasks {
  id: number
  task: Task
  language: string
  completed: boolean | null
  prediction: string
  personalization: string
  postpone_count: number
  week_date: string
}

export const SubscriptionType = {
  FREE: 1,
  PREMIUM: 2,
  PRO: 3
}

export interface Subscription {
  id: number
  type: number
  is_active: boolean
  auto_renewal: boolean
  valid_until: string
}

export interface QuizStep {
  Q1: string
  Q1_1: string
  Q2: string
  Q2_1: string
  Q2_2: string
  Q2_3: string
  Q2_4: string
  Q2_5: string
  Q2_6: string
  Q2_7: string
  Q2_8: string
  Q2_9: string
  Q2_10: string
  Q2_11: string
  Q2_12: string
  Q3: string
  section: string
  genie: string
  step_name: string
}

export interface User {
  id: number
  first_name: string
  last_name: string
  profession: string
  email: string
  birthdate: string
  country: string
  tasks: Tasks[] | null
  subscription: Subscription[]
  social_media_scores?: SocialMediaScore[] | null
  branda_type?: number
  branda_name?: string
  gender?: string
  previous_score?: number
  progress_score?: number
  score?: number
  profession_average_score?: number
  level?: number
  network_strength?: number
  conversion_potential?: number
  benchmark_average?: number
  benchmark_leaders?: number
  benchmark_you?: number
  has_finished_quiz: boolean
  has_finished_steps: boolean
  quiz_step: QuizStep | null | undefined
  goals: string | null
  pre_signup_id: number | null
  has_viewed_task_details: boolean
  remind_me_later_sent: boolean
  is_business: boolean
  business_type: number
  business_type_name: string | null
  brand_name: string | null
  business_offer: string | null
  has_viewed_content_generator: boolean
  has_viewed_welcome_video: boolean
  professional_expertise: string | null
  employee_professional_expertise: string | null
  business_keywords: string | null
  expertise_keywords: string | null
  user_type: string | null
  employee_status: string | null
  employee_professional_title: number | null
  employee_professional_title_name: string | null
  employee_expertise: string | null
  employee_future_employer: string | null
  employee_employer_name: string | null
  employee_employer_offering: string | null
  employee_keywords: string | null
  is_branda_email_sent: boolean
}

export interface QuizState {
  Q1_1: string
  Q2_1: string
  Q2_2: string
  Q2_3: string
  Q2_4: string
  Q2_5: string
  Q2_6: string
  Q2_7: string
  Q2_8: string
  Q2_9: string
  Q2_10: string
  Q2_11: string
  Q2_12: string
  Genie: string
  email: string
  section: string
  userPreSignupId: number
  Gender: string
  Q1: string
  Q2: string
  Q3: string
  Branda: string
  isLoading: boolean
  birthdate: string
  sentEmail: boolean
  currentPage: string
  isChatOpen: boolean
  chatMessage: { message: string, created: string }[]
  password: string
  confirmPassword: string
  first_name: string
  last_name: string
  country: string
  fullNativeName: string
  profession: string
  userFbWeeklyPosts: string
  userIgWeeklyPosts: string
  userLnWeeklyPosts: string
  userTwWeeklyPosts: string
  userSocialPosts: string[]
  userFbPost: string
  userIgPost: string
  expertise_keywords: string
  currentUser: User | null
  profileSettingPage: string
  userNotResponding: number
  isBusiness: boolean
  businessTypeValue: string | null
  brandName: string | null
  brandOffer: string | null
  businessKeywords: string
  keywordInspiration: string | null
  professionalExpertise: string | null
  goals: string | null
  autoViewGuide: boolean
  verificationCode: string
  user_type: string | null
  companyName: string
  websiteUrl: string
  companyNativeName: string
  isBrandReportGenerated: boolean
}

const initialState: QuizState = {
  Q1_1: '',
  Q2_1: '',
  Q2_2: '',
  Q2_3: '',
  Q2_4: '',
  Q2_5: '',
  Q2_6: '',
  Q2_7: '',
  Q2_8: '',
  Q2_9: '',
  Q2_10: '',
  Q2_11: '',
  Q2_12: '',
  Genie: '',
  email: '',
  section: '',
  userPreSignupId: 0,
  Gender: '',
  Q1: '',
  Q2: '',
  Q3: '',
  Branda: '',
  isLoading: true,
  birthdate: '',
  sentEmail: false,
  currentPage: 'email',
  isChatOpen: false,
  chatMessage: [],
  password: '',
  confirmPassword: '',
  first_name: '',
  last_name: '',
  country: 'AL',
  profession: '',
  userFbWeeklyPosts: '',
  userIgWeeklyPosts: '',
  userLnWeeklyPosts: '',
  userTwWeeklyPosts: '',
  userSocialPosts: [],
  userFbPost: '',
  userIgPost: '',
  currentUser: null,
  profileSettingPage: 'init-profile',
  userNotResponding: 0,
  expertise_keywords: '',
  isBusiness: false,
  businessTypeValue: null,
  brandName: null,
  brandOffer: null,
  businessKeywords: '',
  keywordInspiration: null,
  professionalExpertise: null,
  goals: null,
  autoViewGuide: false,
  verificationCode: '',
  user_type: null,
  companyName: '',
  companyNativeName: '',
  websiteUrl: '',
  fullNativeName: '',
  isBrandReportGenerated: false
}

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuestionQ11: (state, action: PayloadAction<string>) => {
      state.Q1_1 = action.payload
    },
    setQuestionQ21: (state, action: PayloadAction<string>) => {
      state.Q2_1 = action.payload
    },
    setQuestionQ22: (state, action: PayloadAction<string>) => {
      state.Q2_2 = action.payload
    },
    setQuestionQ23: (state, action: PayloadAction<string>) => {
      state.Q2_3 = action.payload
    },
    setQuestionQ24: (state, action: PayloadAction<string>) => {
      state.Q2_4 = action.payload
    },
    setQuestionQ25: (state, action: PayloadAction<string>) => {
      state.Q2_5 = action.payload
    },
    setQuestionQ26: (state, action: PayloadAction<string>) => {
      state.Q2_6 = action.payload
    },
    setQuestionQ27: (state, action: PayloadAction<string>) => {
      state.Q2_7 = action.payload
    },
    setQuestionQ28: (state, action: PayloadAction<string>) => {
      state.Q2_8 = action.payload
    },
    setQuestionQ29: (state, action: PayloadAction<string>) => {
      state.Q2_9 = action.payload
    },
    setQuestionQ210: (state, action: PayloadAction<string>) => {
      state.Q2_10 = action.payload
    },
    setQuestionQ211: (state, action: PayloadAction<string>) => {
      state.Q2_11 = action.payload
    },
    setQuestionQ212: (state, action: PayloadAction<string>) => {
      state.Q2_12 = action.payload
    },
    setGenie: (state, action: PayloadAction<string>) => {
      state.Genie = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setSection: (state, action: PayloadAction<string>) => {
      state.section = action.payload
    },
    setUserPreSignupId: (state, action: PayloadAction<number>) => {
      state.userPreSignupId = action.payload
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.Gender = action.payload
    },
    setQuestion1: (state, action: PayloadAction<string>) => {
      state.Q1 = action.payload
    },
    setQuestion2: (state, action: PayloadAction<string>) => {
      state.Q2 = action.payload
    },
    setQuestion3: (state, action: PayloadAction<string>) => {
      state.Q3 = action.payload
    },
    setBranda: (state, action: PayloadAction<string>) => {
      state.Branda = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setBirthdate: (state, action: PayloadAction<string>) => {
      state.birthdate = action.payload
    },
    setSentEmail: (state, action: PayloadAction<boolean>) => {
      state.sentEmail = action.payload
    },
    setCurrentQuizPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload
    },
    setConfirmPassword: (state, action: PayloadAction<string>) => {
      state.confirmPassword = action.payload
    },
    setUserFbWeeklyPosts: (state, action: PayloadAction<string>) => {
      state.userFbWeeklyPosts = action.payload
    },
    setUserIgWeeklyPosts: (state, action: PayloadAction<string>) => {
      state.userIgWeeklyPosts = action.payload
    },
    setUserLnWeeklyPosts: (state, action: PayloadAction<string>) => {
      state.userLnWeeklyPosts = action.payload
    },
    setUserTwWeeklyPosts: (state, action: PayloadAction<string>) => {
      state.userTwWeeklyPosts = action.payload
    },
    setUserSocialPosts: (state, action: PayloadAction<string[]>) => {
      state.userSocialPosts = action.payload
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.first_name = action.payload
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.last_name = action.payload
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload
    },
    setProfession: (state, action: PayloadAction<string>) => {
      state.profession = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload
    },
    setCurrentUserStep: (state, action: PayloadAction<string>) => {
      if (state.currentUser?.quiz_step?.step_name === undefined) {
        return
      }

      state.currentUser.quiz_step.step_name = action.payload
    },
    setIsChatOpen: (state, action: PayloadAction<boolean>) => {
      state.isChatOpen = action.payload
    },
    setChatMessage: (state, action: PayloadAction<{ message: string, created: string }[]>) => {
      state.chatMessage = action.payload
    },
    setProfileSettingPage: (state, action: PayloadAction<string>) => {
      state.profileSettingPage = action.payload
    },
    setUserFbPost: (state, action: PayloadAction<string>) => {
      state.userFbPost = action.payload
    },
    setUserIgPost: (state, action: PayloadAction<string>) => {
      state.userIgPost = action.payload
    },
    setUserNotResponding: (state, action: PayloadAction<number>) => {
      state.userNotResponding = action.payload
    },
    setExpertiseKeywords: (state, action: PayloadAction<string>) => {
      state.expertise_keywords = action.payload
    },
    setIsBusiness: (state, action: PayloadAction<boolean>) => {
      state.isBusiness = action.payload
    },
    setBusinessTypeValue: (state, action: PayloadAction<string | null>) => {
      state.businessTypeValue = action.payload
    },
    setUserBrandName: (state, action: PayloadAction<string | null>) => {
      state.brandName = action.payload
    },
    setUserBrandOffer: (state, action: PayloadAction<string | null>) => {
      state.brandOffer = action.payload
    },
    setBusinessKeywords: (state, action: PayloadAction<string>) => {
      state.businessKeywords = action.payload
    },
    setKeywordInspiration: (state, action: PayloadAction<string | null>) => {
      state.keywordInspiration = action.payload
    },
    setGoals: (state, action: PayloadAction<string | null>) => {
      state.goals = action.payload
    },
    setAutoViewGuide: (state, action: PayloadAction<boolean>) => {
      state.autoViewGuide = action.payload
    },
    setVerificationCode: (state, action: PayloadAction<string>) => {
      state.verificationCode = action.payload
    },
    setUserType: (state, action: PayloadAction<string | null>) => {
      state.user_type = action.payload
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload
    },
    setCompanyNativeName: (state, action: PayloadAction<string>) => {
      state.companyNativeName = action.payload
    },
    setWebsiteUrl: (state, action: PayloadAction<string>) => {
      state.websiteUrl = action.payload
    },
    setFullNativeName: (state, action: PayloadAction<string>) => {
      state.fullNativeName = action.payload
    },
    setIsBrandReportGenerated: (state, action: PayloadAction<boolean>) => {
      state.isBrandReportGenerated = action.payload
    },
    clearAll: (state) => {
      state.Q1 = ''
      state.Q2 = ''
      state.Q3 = ''
      state.Q1_1 = ''
      state.Q2_1 = ''
      state.Q2_2 = ''
      state.Q2_3 = ''
      state.Q2_4 = ''
      state.Q2_5 = ''
      state.Q2_6 = ''
      state.Q2_7 = ''
      state.Q2_8 = ''
      state.Q2_9 = ''
      state.Q2_10 = ''
      state.Q2_11 = ''
      state.Q2_12 = ''
      state.Genie = ''
      state.Gender = ''
    }
  }
})

export const
  {
    setQuestionQ11,
    setQuestionQ21,
    setQuestionQ22,
    setQuestionQ23,
    setQuestionQ24,
    setQuestionQ25,
    setQuestionQ26,
    setQuestionQ27,
    setQuestionQ28,
    setQuestionQ29,
    setQuestionQ210,
    setQuestionQ211,
    setQuestionQ212,
    setEmail,
    setGenie,
    setSection,
    setUserPreSignupId,
    setQuestion1,
    setQuestion2,
    setQuestion3,
    setGender,
    setBranda,
    setIsLoading,
    setBirthdate,
    setSentEmail,
    setCurrentQuizPage,
    setPassword,
    setConfirmPassword,
    setUserFbWeeklyPosts,
    setUserIgWeeklyPosts,
    setUserLnWeeklyPosts,
    setUserTwWeeklyPosts,
    setUserSocialPosts,
    setCurrentUser,
    setIsBusiness,
    setCountry,
    setProfession,
    setBusinessTypeValue,
    setUserBrandName,
    setFirstName,
    setLastName,
    setUserFbPost,
    setUserIgPost,
    setIsChatOpen,
    setChatMessage,
    setProfileSettingPage,
    setUserNotResponding,
    setExpertiseKeywords,
    setUserBrandOffer,
    setBusinessKeywords,
    setKeywordInspiration,
    setGoals,
    setAutoViewGuide,
    setVerificationCode,
    setCurrentUserStep,
    setUserType,
    clearAll,
    setCompanyName,
    setCompanyNativeName,
    setWebsiteUrl,
    setIsBrandReportGenerated
  } = quizSlice.actions

export default quizSlice.reducer
