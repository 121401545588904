/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Heading from '../../atoms/Heading/Heading'
import VerificationInput from 'react-verification-input'
import { toast } from 'react-toastify'
import {preSignUpUser, users} from '../../../app/services/Api'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {PAGE, setCurrentUser, setEmail, setUserPreSignupId, setVerificationCode} from '../../../app/quiz/quizSlice'
import {setCredits} from "../../../app/quiz/userProfile";

interface Props {
  className: string
  handleShowPage: (name: string) => void
}

export default function QuizVerificationPage ({
  className,
  handleShowPage
}: Props) {
  const email = useAppSelector(state => state.quizReducer.email)
  let firstName = useAppSelector(state => state.quizReducer.first_name)
  let lastName = useAppSelector(state => state.quizReducer.last_name)
  const [isDisabled, setIsDisabled] = useState(true)
  const [codeValue, setCodeValue] = useState('')
  const dispatch = useAppDispatch()
  const query = new URLSearchParams(location.search)
  const code = query.get('code')

  useEffect(() => {
    if (query.get('first_name')) {
      const user = JSON.stringify({
        email: query.get('email') ?? '',
        name: query.get('first_name') ?? '',
        last_name: query.get('last_name') ?? '',
        country: query.get('country') ?? '',
        gender: query.get('gender') ?? ''
      })

      dispatch(setEmail(query.get('email')?.replaceAll(' ', '+') ?? ''))
      setUserPreSignupId(Number(query.get('id')) ?? 0)
      localStorage.setItem('pre_sign_up_user', user.replaceAll(' ', '+'))
    }

    handleChange(code ?? '')
  }, [])

  const handleChange = (value: string) => {
    setCodeValue(value)
    if (value.length === 6) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }
  const handleVerificationCode = async () => {
    const stringifiedUserFromStorage = JSON.parse(localStorage.getItem('pre_sign_up_user') ?? '')
    await preSignUpUser.validateVerificationCode(stringifiedUserFromStorage.email, codeValue).then((response) => {
      if (response.response !== undefined && response.response.data.message) {
        toast.warning(response.response.data.message)
      } else {
        void users.registerPartial(email, firstName, lastName, stringifiedUserFromStorage.gender).catch(() => {
          toast.error('Failed to change page. Please try again!')
        }).finally(() => {
          void users.signIn(email, codeValue, true).then((response) => {
            if (response.jwt) {
              dispatch(setVerificationCode(codeValue))
              dispatch(setCurrentUser(response.user))
              dispatch(setCredits(0))
              handleShowPage(PAGE.GOALS)
            }
          }).catch(err => {
            toast.error(err)
            setIsDisabled(false)
          })
        })

        dispatch(setUserPreSignupId(response.id))
      }
    }).catch(() => {
      toast.error('Invalid confirmation code.')
    })
  }

  useEffect(() => {
    if (email && query.get('first_name')) {
      handleResendCode().catch(() => { })
    }
  }, [email])

  const handleResendCode = async () => {
    await preSignUpUser.resendVerificationCode(email).then((response) => {
      toast.info('Verification code sent. Check your email.')
      if (!(response.response !== undefined && response.response.data.message)) {
        dispatch(setUserPreSignupId(response.id))
      }
    }).catch(() => {
      toast.error('Failed to resend verification code.')
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await handleVerificationCode()
  }

  return (
    <div className={className}>
      <form onSubmit={handleSubmit} className="flex flex-col justify-center relative visible_1 items-center w-full md:w-auto">
        <div className="flex flex-col justify-center items-start gap-5 px-5 mt-8 md:mt-0 w-full">
          <Heading className="" text="Verify Email" />
          <Heading
            className=""
            text="Enter the confirmation code we sent to your email."
          />
          <VerificationInput
            value={codeValue}
            onChange={(value) => { handleChange(value) }}
            validChars="0-9"
            inputProps={{ inputMode: 'numeric' }}
            placeholder=''
            classNames={{
              container: 'container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected'
            }}
          />
          <p className="text-purple-700 text-xs text-left">
            Didn&apos;t receive an email from us? Check your spam and mark myBranda as &apos;safe&apos;. <br></br>
            <span
              className="underline cursor-pointer font-medium block mt-2 text-left"
              onClick={handleResendCode}
            >
              Not there? Regenerate code!
            </span>
          </p>
        </div>
        <button
          disabled={isDisabled}
          className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center mt-16"
        >
          Verify Email
        </button>
      </form>
    </div>
  )
}
