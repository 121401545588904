import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect } from 'react'
import { ReactComponent as Image } from '../../../img/brand-report-short-img.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { preSignUpUser, users } from '../../../app/services/Api'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import {
  setCompanyName,
  setCountry,
  setEmail,
  setFirstName, setGender,
  setLastName,
  setProfession
} from '../../../app/quiz/quizSlice'
import { useAppSelector } from '../../../app/hooks'

export default function BrandReportShort () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [shortReport, setShortReport] = React.useState<string>('')
  const [isRTL, setIsRTL] = React.useState<boolean>(false)
  const [isButtonVisible, setIsButtonVisible] = React.useState<boolean>(true)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  const query = new URLSearchParams(location.search)
  const isShared = query.get('shared') !== null

  const handleSubmit = () => {
    navigate('/about-us')
  }

  useEffect(() => {
    if (id === undefined) {
      return
    }

    void users.getReport(id).then((response) => {
      setShortReport(response.full_report.replaceAll(/\[\d+]/gm, ''))
      setIsRTL(response.country === 'IL')

      if (isShared) {
        return
      }

      localStorage.removeItem('pre_sign_up_user')
      void preSignUpUser.sendEmailVerification(
        response.email,
        response.first_name,
        response.last_name,
        response.country,
        true
      ).then(() => {
        dispatch(setLastName(response.last_name))
        dispatch(setFirstName(response.first_name))
        dispatch(setEmail(response.email))
        dispatch(setCountry(response.country))
        dispatch(setCompanyName(response.workplace))
        dispatch(setProfession(response.occupation))
        dispatch(setGender(response.gender))
      }).catch(() => {
        dispatch(setEmail(response.email))
      })
    })
  }, [])

  useEffect(() => {
    setIsButtonVisible(currentUser === null)
  }, [currentUser])

  return (
    <div>
      <div className='flex flex-col justify-center relative items-center gap-2 visible_1'>
        <Image className='w-36 md:w-48'/>
        <div className='flex flex-col justify-center items-center gap-3'>
          <p className='main_header_home text-center text-2xl md:text-3xl font-medium'>Your Human Brand Design</p>
          <p className='text-base font-normal text-center md:text-left break-words w-64 md:w-auto'>
            The matching content strategy for your personality
          </p>
        </div>
      </div>
      <div className='flex pt-2 flex-col justify-center relative items-center visible_1 gap-2 mt-5'>
        <ReactMarkdown
          className={`${isRTL ? 'rtl' : ''} react-md border rounded-2xl bg-white shadow-md p-4 min-h-64 overflow-y-auto w-full md:w-2/3`}>
          {shortReport}
        </ReactMarkdown>

        {isButtonVisible && (
          <>
            <div
              className='text-center flex flex-col items-center rounded-2xl bg-gray-50 shadow-md p-4 overflow-y-auto w-full md:w-2/3 mt-6 py-6'>
              <p className='main_header_home font-medium text-xl text-center mb-4 capitalize'>Start building your Online
                Human Brand!</p>
              <div>
                <p className='text-left mb-1 md:mb-0'>
                  <div className='flex'>
                    <span>⭐</span>
                    <span className='flex-1 ml-1.5'>Discover your <b>Branda:</b> <br className='md:hidden'/> Your Personal Brand identity to maintain online.</span>
                  </div>
                </p>
                <p className='text-left mb-1 md:mb-0'>
                  <div className='flex'>
                    <span>⭐</span>
                    <span className='flex-1 ml-1.5'>Get personality-based social media content written for you with myBranda®</span>
                  </div>
                </p>
                <p className='text-left mb-1 md:mb-0'>
                  <div className='flex'>
                    <span>⭐</span>
                    <span className='flex-1 ml-1.5'>Unlock your full Personal Brand&apos;s potential and write consistently with FLOW.</span>
                  </div>
                </p>
              </div>

              <LoadingButton
                onClick={handleSubmit}
                loading={false}
                loadingIndicator={<CircularProgress color="inherit" size={28}/>}
                color='inherit'
                className='quiz_form_button quiz_form_submition !mx-auto !normal-case !text-white !font-medium !rounded-lg px-5 py-2.5 text-center !mt-6 mr-2 h-14'
              >Build My Brand!</LoadingButton>
            </div>
          </>
        )}

        <div className='mt-12'>
          <p className='text-center font-bold capitalize'>Invite Friends To Receive Their Human Branding Story! 🙏</p>
          <div className="flex justify-center gap-4 pt-4">
            <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmybranda.com%2Fmybrandid%2F${id}?shared=true`} rel="noreferrer">
              <img src="https://my-branda-s3-bucket.s3.us-east-1.amazonaws.com/email-images/fb-rounded.png"
                   height="26" width="26"/>
            </a>
            <a target='_blank' href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fmybranda.com%2Fmybrandid%2F${id}?shared=true%2F&text=Special%20offer%20for%20your%20brand%20growth%21%20%F0%9F%AA%B4`} rel="noreferrer">
              <img src="https://my-branda-s3-bucket.s3.us-east-1.amazonaws.com/email-images/twitter-rounded.png"
                   height="26" width="26"/>
            </a>
            <a target='_blank' href="https://www.instagram.com/mybranda.insta/" rel="noreferrer">
              <img src="https://my-branda-s3-bucket.s3.us-east-1.amazonaws.com/email-images/instagram-rounded.png"
                   height="26" width="26"/>
            </a>
            <a target='_blank' href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fmybranda.com%2Fmybrandid%2F${id}?shared%2F&title%3DSpecial%20offer%20for%20your%20brand%20growth%21%20%F0%9F%AA%B4&source=mybranda.com`} rel="noreferrer">
              <img src="https://my-branda-s3-bucket.s3.us-east-1.amazonaws.com/email-images/linkedin-rounded.png"
                   height="26" width="26"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
