import BrandReportForm from './BrandReportForm'
import BrandReportShort from './BrandReportShort'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function BrandReport () {
  const { id } = useParams()

  return (
    <>
      <Helmet>
        <meta property="og:description" content="I already got my Human Brand content strategy. Check your own for FREE!" data-react-helmet="true" />
        <meta property="og:image:aspect_ratio" content="1.91:1" />
        <meta property="og:image" content="https://d3nsqgxbbxtdc8.cloudfront.net/mybrandid-meta-horizontal.png" data-react-helmet="true" />

        <meta name="twitter:image" content="https://d3nsqgxbbxtdc8.cloudfront.net/mybrandid-meta-square.png" data-react-helmet="true" />

      </Helmet>
      <div className='px-4 quiz_email_page visible bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-12 mb-10 md:mb-28'>
        {id === undefined && <BrandReportForm />}
        {id !== undefined && <BrandReportShort />}
      </div>
    </>
  )
}
