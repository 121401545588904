/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, Sidebar as MobileSidebar } from 'react-pro-sidebar'
import { Route, Routes, useNavigate } from 'react-router-dom'
import QuizNavbar from 'components/organisms/QuizNavbar/QuizNavbar'
import Sidebar from '../components/organisms/Sidebar/Sidebar'
import Footer from '../components/organisms/Footer/Footer'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import Quiz from '../components/templates/Quiz/Quiz'
import ContactUs from '../components/templates/ContactUs/ContactUs'
import Subscription from '../components/templates/Subscription/Subscription'
import Support from '../components/templates/Support/Support'
import Profile from '../components/templates/Profile/Profile'
import { ProtectedRoute } from '../app/services/ProtectedRoute'
import AccountSettings from 'components/molecules/ProfileForm/AccountSettings'
import ScrollToTop from 'app/services/ScrollToTop'
import { setProfileSettingPage } from 'app/quiz/quizSlice'
import ProfessionAdmin from 'components/templates/AdminPages/Profession/ProfessionAdmin'
import ContentGenerator from '../components/templates/ContentGenerator/ContentGenerator'
import TopicSelector from '../components/templates/ContentGenerator/TopicSelector'
import BrandReport from '../components/templates/BrandReport/BrandReport'

export default function QuizPage () {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const currentPage = useAppSelector(state => state.quizReducer.currentPage)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  const updateScreenWidth = (): void => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  const manageSubscriptionPlanProfile = (): void => {
    dispatch(setProfileSettingPage('profile-subscription'))
  }

  return (
    <div className="relative contactus_content min-h-screen">
      <QuizNavbar handleClick={setCollapsed} collapsed={collapsed} />
      {!collapsed && !isScreenWidthAbove768
        ? (
            <MobileSidebar className='!absolute w-full h-4/5 mt-5 lg:hidden' collapsedWidth='0' width='100%' collapsed={collapsed}>
              <Menu>
                <MenuItem onClick={() => { navigate('/'); setCollapsed(true) }}>Home</MenuItem>
                {currentUser?.has_finished_quiz && (
                  <MenuItem onClick={() => { navigate('/content-generator'); setCollapsed(true) }}>Content Generator </MenuItem>
                )}
                {currentUser && (<MenuItem onClick={() => { manageSubscriptionPlanProfile(); navigate('/profile'); setCollapsed(true) }}> Subscription Plan</MenuItem>)}
                {currentUser?.has_finished_quiz && (
                  <MenuItem onClick={() => { navigate('/mybrandid'); setCollapsed(true) }}>myBrand Id</MenuItem>
                )}
                <MenuItem onClick={() => { navigate('/about-us'); setCollapsed(true) }}>About Us</MenuItem>
                <MenuItem onClick={() => { navigate('/contact-us'); setCollapsed(true) }}>Contact Us</MenuItem>
                <MenuItem onClick={() => { navigate('/support'); setCollapsed(true) }}>Support</MenuItem>
              </Menu>
            </MobileSidebar>
          )
        : (
            <div className="quiz_sidebar_content flex flex-row justify-center lg:justify-start items-start pt-5 md:gap-3 lg:gap-5 container-md mb-24 md:mb-0 mx-1 md:m-auto">
              <ScrollToTop />
              <Sidebar className="quiz_sidebar hidden lg:flex flex-col justify-center items-center gap-5 basis-2/12 mx-2" currentPage={currentPage} />
              <Routes>
                <Route path='/quiz' element={<Quiz />} />
                <Route path='/contact-us' element={<ContactUs />} />
                  <Route path='/mybrandid' element={<BrandReport />} />
                  <Route path='/mybrandid/:id' element={<BrandReport />} />
                <Route path='/subscription' element={
                  <ProtectedRoute>
                    <Subscription />
                  </ProtectedRoute>
                }/>
                <Route path='/support' element={<Support />} />
                <Route path='/profession-admin' element={<ProfessionAdmin />} />
                <Route path='/accountsettings' element={<AccountSettings />} />
                <Route path='/content-generator/:id/history/:historyId' element={
                  <ProtectedRoute>
                    <ContentGenerator />
                  </ProtectedRoute>
                } />
                <Route path='/content-generator/:id' element={
                  <ProtectedRoute>
                    <ContentGenerator />
                  </ProtectedRoute>
                } />
                <Route path='/content-generator' element={
                  <ProtectedRoute>
                    <TopicSelector />
                  </ProtectedRoute>
                } />
                <Route path='/profile' element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                } />
              </Routes>
            </div>
          )
      }
      {collapsed || isScreenWidthAbove768
        ? (
          <Footer className='w-full min-h-20 p-3 md:absolute bottom-0 bg-white md:rounded-t-3xl items-start md:items-center flex flex-col md:flex-row justify-between' />
          )
        : (<></>)
      }
    </div>
  )
}
